<template>
    <CModal :title="title" color="danger" :show.sync="deleteModal">   
        <p style="font-weight: bold;">¿Estás seguro que deseas eliminar el registro seleccionado?</p>
        <br>
        El registro <b>{{name}}</b> será eliminado de la base de datos.

        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="deleteModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="deleteModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="destroy" color="danger">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {

  },
  data () {
    return {
      deleteModal: false,
      title: "Eliminar Registro",
      id: 0,
      name: 'Registro'
    }
  },
  methods: {
    show (title, id, name) {
      this.deleteModal = true;
      this.title = title;
      this.id = id;
      this.name = name;
    },
    destroy () {
      this.deleteModal = false;
      this.$emit("delete", this.id );
    }
  }
}
</script>